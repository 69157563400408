.navBar{
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0.5rem #00000075 ;
    color: white;
    backdrop-filter: blur(5px);
}

.logo{
    height: 30px;
    width: auto;
}